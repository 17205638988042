/* eslint-disable camelcase */
import React, { FC, useState, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { PageLayout, Pagination, PageHeading, FAQ } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { truncate } from 'utils';
import { DieticianPagetype } from 'types';

const PageSize = 8;

const Dietitians: FC<DieticianPagetype> = ({ data }: any) => {
  const {
    dieticians,
    faqSection: {
      title: pagetitle,
      pageDescription,
      faq: { title: faqTitle, tagline: faqTagline, faqs },
      seo: { metaDescription, metaTitle, shareImage },
    },
    serviceBanner,
  } = data;
  const { banner } = serviceBanner;
  const [currentPage, setCurrentPage] = useState(1);
  const titleref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleonClick = (page: any) => {
    setCurrentPage(page);
    window.scrollTo(0, titleref.current.offsetTop);
  };

  return (
    <PageLayout
      title="Dietician"
      metaTitle={metaTitle ?? pagetitle}
      description={metaDescription}
      image={shareImage?.localFile?.publicURL}
      hasContainerClass
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Nutrition Page"
      faqs={faqs}
    >
      <>
        <PageHeading
          title={pagetitle}
          description={pageDescription}
          level={1}
        />
        <section className="dietitian-list">
          {dieticians.edges.map((dietician: any) => {
            const { id, title, description, image, slug, qualification } =
              dietician.node;
            const dietitianImage: any = image
              ? getImage(image.localFile.childImageSharp)
              : null;
            const content = truncate(description, 140);
            return (
              <div className="dietitian-section-single-item" key={id}>
                <div className="dietitian-section-single-item-wrapper">
                  <div className="dietitian-section-single-item-image">
                    <Link to={`/services/nutrition/${slug}`}>
                      {dietitianImage && (
                        <GatsbyImage
                          image={dietitianImage}
                          alt={image?.alternativeText ?? (title || '')}
                        />
                      )}
                    </Link>
                  </div>
                  <div className="dietitian-section-single-item-qualification">
                    {qualification}
                  </div>
                  <div className="dietitian-section-single-item-detail">
                    <div className="dietitian-section-single-item-detail-wrapper">
                      <h4 className="dietitian-section-single-item-title">
                        <Link to={`/services/nutrition/${slug}`}>{title}</Link>
                      </h4>

                      <div className="dietitian-section-single-item-description">
                        <ReactMarkdown className="md-html">
                          {content}
                        </ReactMarkdown>
                      </div>
                    </div>
                    <Link to={`/services/nutrition/${slug}`}>
                      <div className="dietitian-section-single-item-contact">
                        <span> Book Consulting Dietitian </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
        <Pagination
          currentPage={currentPage}
          totalCount={dieticians.edges.length}
          pageSize={PageSize}
          onPageChange={handleonClick}
        />
        <section className="home-page-faq-section">
          <PageHeading title={faqTitle} subtitle={faqTagline} />
          <FAQ Faqs={faqs} />
        </section>
      </>
    </PageLayout>
  );
};

export default Dietitians;

export const query = graphql`
  query DietitianPageQuery {
    dieticians: allStrapiDietitian {
      edges {
        node {
          description
          title
          slug
          qualification
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 520, height: 520)
              }
            }
          }
        }
      }
    }
    serviceBanner: strapiServicePage(slug: { eq: "nutrition" }) {
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    faqSection: strapiDietitianPage {
      title
      pageDescription
      seo {
        metaDescription
        metaTitle
      }
      faq {
        tagline
        title
        faqs {
          title
          answer
        }
      }
    }
  }
`;
